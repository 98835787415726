import { StyleSheet, Text, View, FlatList, Image, Dimensions, TouchableOpacity, useWindowDimensions } from 'react-native'
import React, { useState, useEffect } from 'react'
import Icon from 'react-native-vector-icons/MaterialIcons';

import MenuButton from '../components/MenuButton'

const windowDimensions = Dimensions.get('window');
const width = windowDimensions.width;
const largeScreenRes = 900;


const ShopScreen = (props) => {
    const [styles, setStyles] = useState(stylesD);

    useEffect(() => {
        if (0 <= width && width < largeScreenRes) {
            // Default Styles
            setStyles(stylesD);
        } else {
            // Large Screen Styles
            setStyles(stylesL);
        }
    }, [width]);

    const shopsData = [
        {
            imageUri: require("../images/delposto.png"),
            url: 'https://delposto-menu.com'
        },
        {
            imageUri: require("../images/plateia.png"),
            url: 'https://plateia-menu.com'
        },
        {
            imageUri: require("../images/il_nido.png"),
            url: 'https://ilnido-menu.000webhostapp.com/index.php?animation=true'
        },
        {
            imageUri: require("../images/pefkorama.png"),
            url: 'https://pefkorama-menu.com/index.php?animation=true'
        },
        {
            imageUri: require("../images/kipos.png"),
            url: 'https://kipos-menu.com/index.php?animation=true'
        },
        {
            imageUri: require("../images/belvere.png"),
            url: 'https://belvere-menu.000webhostapp.com/index.php?animation=true'
        },
        {
            imageUri: require("../images/giannis.png"),
            url: 'https://giannis-menu.000webhostapp.com/index.php?animation=true'
        }
    ]

    const returnBackHandler = () => {
        navigation.navigate('Home')
    }


    let shopListView = <FlatList
        style={styles.shopList}
        contentContainerStyle={styles.shopListContainer}
        data={shopsData}
        renderItem={({ item, index }) => (
            <MenuButton
                imageUri={item.imageUri}
                url={item.url}
                buttonWrappper = {stylesD.buttonWrappper}
                imageStyle = {stylesD.shopImage}
            />
        )}
        keyExtractor={(item, index) => index}
    />
    if (width >= largeScreenRes ){
        shopListView = <FlatList
        style={styles.shopList}
        contentContainerStyle={styles.shopListContainer}
        data={shopsData}
        renderItem={({ item, index }) => (
            <MenuButton
                imageUri={item.imageUri}
                url={item.url}
                buttonWrappper = {stylesL.buttonWrappper}
                imageStyle = {stylesL.shopImage}
            />
        )}
        numColumns={2}
        keyExtractor={(item, index) => index}
    />  
    }



    return (
        <View style={styles.screen}>
            <View style={styles.header}>
                <TouchableOpacity>
                    {/* <Image
                        style={styles.headerBackArrow}
                        source={require('../images/back_arrow.png')} /> */}
                </TouchableOpacity>
                <Image style={styles.headerTitleImage} source={require('../images/qr_header_title.png')} />
            </View>
            {shopListView}
        </View>
    )
}

export default ShopScreen

const stylesD = StyleSheet.create({
    screen: {
        flex: 1,
        alignContent: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(10, 2, 28, 1.0)',

    },
    header: {
        marginTop: 30,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'space-between',
        marginHorizontal: 10
    },
    headerBackArrow: {
        marginStart: 10,
        width: windowDimensions.width * (1.2 / 10) * 0.4,
        height: windowDimensions.width * (1.2 / 10) * 0.4 * (21 / 12)
    },
    headerTitleImage: {
        width: windowDimensions.width * 0.5,
        height: windowDimensions.width * 0.5 * (2.1 / 10)
    },
    shopListContainer: {

        flexGrow: 1,
        justifyContent: 'center'
    },
    shopList: {
        marginTop: 20,
        marginHorizontal: 10,
    },
    buttonWrappper: {
        marginVertical: 10,
        alignContent: 'center',
        justifyContent: 'center',
    },
    shopImage:{
        width: windowDimensions.width *0.95 ,
        height: windowDimensions.width *0.95 * (3/10),
    },

})

const stylesL = StyleSheet.create({
    screen: {
        flex: 1,
        alignContent: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(10, 2, 28, 1.0)',

    },
    header: {
        marginTop: 30,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'space-between',
        marginHorizontal: 10
    },
    headerBackArrow: {
        marginStart: 10,
        width: windowDimensions.width * (1.2 / 10) * 0.2,
        height: windowDimensions.width * (1.2 / 10) * 0.2 * (21 / 12)
    },
    headerTitleImage: {
        width: windowDimensions.width * 0.3,
        height: windowDimensions.width * 0.3 * (2.1 / 10)
    },
    shopListContainer: {
        flexGrow: 1,
        justifyContent: 'center'
    },
    shopList: {
        marginTop: 20,
        marginHorizontal: 10,
    },
    buttonWrappper: {
        marginVertical: 30,
        marginHorizontal: 5,
        alignContent: 'center',
        justifyContent: 'center',
    },
    shopImage:{
        width: windowDimensions.width *0.95 * 0.5 ,
        height: windowDimensions.width *0.95 * 0.5 * (3/10),
    },
})