import { StyleSheet, Text, TouchableOpacity, View, Image, Dimensions, Platform, Linking } from 'react-native'
import React from 'react'


const windowDimensions = Dimensions.get('window');

const MenuButton = props => {
    let imageSource = '../images/' + props.imageTitle + '.png';

    const openLinkHandler = () => {
        if(Platform.OS == 'web'){
            window.open(props.url, '_blank');
         } else {
            Linking.openURL(props.url).catch((err) => console.error('An error occurred', err));
         }
    }

    return (
        <TouchableOpacity
            style={props.buttonWrappper}
            onPress={openLinkHandler}
        >
            <Image
                style={props.imageStyle}
                source={props.imageUri}
            />
        </TouchableOpacity>
    )
}

export default MenuButton

const styles = StyleSheet.create({
    
    
})