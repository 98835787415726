import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

const HomeScreen = (props) => {
  return (
    <View>
      <Text>HomeScreen</Text>
    </View>
  )
}

export default HomeScreen

const styles = StyleSheet.create({})